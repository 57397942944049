/*.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 100vh;
}*/

iframe{
    display: unset !important;
}

.customizedLoginButton{
    border: #1a686c 2px solid;
    width: 20%;
    align-content: center;
}

.toggle {
    padding: 0.65rem 1.25rem;
    border: none;
    box-shadow: 0rem 0rem 0.25rem rgba(0, 0, 0, 0.25);
    border-radius: 1.4rem;
    font-size: 1rem;
    font-weight: 600;
    width: 12.5rem;
    height: 2.5rem;
    margin-top: 1.8rem;
}

/*.header {*/
/*    padding: 0.65rem;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    margin-bottom: 4.375rem;*/
/*}*/

.mainLogo {
    height: 3.5rem;
    margin: 1.25rem;
}

.login {
    color: #d41c68;
    font-size: 1rem;
    font-weight: 600;
    margin: 1rem;
}

#gsi_460589_515527{
    display: unset;
}

.fade-in {
    animation: fadeIn;
    animation-duration: 0.6s;
}

.container4 {
    margin: 3.75rem auto 50px auto;
    width: 80%;
    position: relative;
    height: 5rem;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
}

.custom{
    position: absolute;
    left: 0; 
    right: 0; 
    top: 9rem;
    margin-left: auto; 
    margin-right: auto; 
}
.custom1{
    position: absolute;
    left: 0; 
    right: 0; 
    top: 20rem;
    margin-left: auto; 
    margin-right: auto; 
}

.container4 img {
    /* margin-top: 0.25rem; */
    align-content: center;
    align-items: center;
    width: 54.688rem;
    height: 5.188rem;
    position: absolute;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; }

.container4 h1 {
    /* left: 19rem; */
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 3rem;
    line-height: 100%;
    align-items: center;
    letter-spacing: -0.025em;
    color: #D41C68;
    position: absolute;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto; 
}

.container4 h1,.h1{
    margin-top: 2rem;
}

.container3 {
    text-align: center;
    margin-top: 2rem;
    align-content: center;
    /* margin-left: 45rem; */
}

.container3 p {
    margin-top: 15.5rem;
    font-size: 0.8rem;
    font-weight: 300;
}

.heading3 {
    color: #d41c68;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1.25rem;
}

.container2 {
    width: 100%;
    height: 7.5rem;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0;
    margin-left: -0.625rem;
}

.footerHeading {
    text-align: center;
    margin: 0 0.9rem;
}

.footerHeading p,
h3 {
    margin: 0;
}

.footerHeading h3 {
    font-size: 1.5rem;
    font-weight: 500;
}

.footerImage {
    border-radius: 50%;
    width: 3.75rem;
    height: 3.75rem;
    margin: 0 0.65rem;
    border: 0.2rem solid #d41c68;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media only screen and (max-width: 48rem) {
    .mainLogo {
        width: 9rem;
        height: 3.125rem;
        margin-right: 0rem;
    }

    .container3 {
        margin-top: 12.5rem;
    }

    .heading3 {
        font-size: 1rem;
    }

    .container4 {
        height: 4.18rem;
    }

    .container4 h1 {
        left: 2rem;
        top: 12.9rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 1.7rem;
        line-height: 100%;
        align-items: center;
        letter-spacing: -0.025em;
        color: #D41C68;
        position: absolute;

    }

    .container4 p {
        width: 18.75rem;
        font-size: 1rem;
        margin-bottom: -8.75rem;

        margin-left: -1rem;
    }

    .container4 img {
        height: 5.188rem;
        position: absolute;
        left: 0.4rem;
        margin-top: 12.25rem;
        width: 18.125rem;
    }

    .container2 {
        justify-content: center;
        padding-top: 100px;
        position: unset;
    }

    .footerImage {
        display: none;
    }

    .google-icon-wrapper {
        position: absolute;
        margin-top: 1px;
        margin-left: 1px;
        width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: #fff;
    }

    .google-icon {
        position: absolute;
        margin-top: 11px;
        margin-left: 11px;
        width: 18px;
        height: 18px;
    }

    .btn-text {
        float: right;
        margin: 11px 11px 0 0;
        color: #fff;
        font-size: 14px;
        letter-spacing: 0.2px;
    }

    :where:hover {
        box-shadow: 0 0 6px #fff;
    }

    :where:active {
        background: #fff;
    }
}


.google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #fff;
}

.google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
}

.btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: #1F1F1F;
    font-size: 14px;
    letter-spacing: 0.2px;
}

:where:hover {
    box-shadow: 0 0 6px #fff;
}

:where:active {
    background: #fff;
}
    
      
    