.reportTable {
    height: max-content;
    font-family: "Inter";
    font-size: medium;
}

.reportTable > table {
    width: 100% !important;
}

.reportTable > div > table {
    width: 100% !important;
}

.reportTable > table > tbody > tr:first-child {
    background-color: #f2f2f2;
    font-weight: bold;
}

.reportTable > div > table > tbody > tr:first-child {
    background-color: #f2f2f2;
    font-weight: bold;
}

.reportTable > table > colgroup > col:first-child {
    width: 15% !important;
}

.reportTable > div > table > colgroup > col:first-child {
    width: 15% !important;
}

.reportTable > table > tbody > tr > td:first-child {
    /*text-align: center;*/
    padding-left: 15px;
}

.reportTable > div > table > tbody > tr > td:first-child {
    padding-left: 15px;
}

.reportTable > table > tbody > tr:nth-child(odd) {
    background-color: #f2f2f2;
}

.reportTable > div > table > tbody > tr:nth-child(odd) {
    background-color: #f2f2f2;
}